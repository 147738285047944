/* General container styling */
.loading-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(135deg, #871915, #3c3c3c);
    color: #f5f5f5;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    position: relative;
    overflow: hidden;
}

/* Enlarged 3D cube styling */
.cube {
    position: relative;
    width: 450px;
    height: 550px;
    transform-style: preserve-3d;
    animation: rotate 2s infinite linear;  /* Reduced duration for faster rotation */
    z-index: 1;
}

/* Cube face colors with theme-based gradient including orange */
.cube .face {
    position: absolute;
    width: 170px;
    height: 170px;
    background: linear-gradient(135deg, #871915, #ff7f50);
    border: 1px solid #4f0d0d;
    box-shadow: 0 0 20px rgba(255, 127, 80, 0.5);
    border-radius: 5px;
}

/* Adjusted face positions for the larger cube */
.face1 { transform: rotateY(0deg) translateZ(100px); }
.face2 { transform: rotateY(90deg) translateZ(100px); }
.face3 { transform: rotateY(180deg) translateZ(100px); }
.face4 { transform: rotateY(-90deg) translateZ(100px); }
.face5 { transform: rotateX(90deg) translateZ(100px); }
.face6 { transform: rotateX(-90deg) translateZ(100px); }

/* Faster rotation animation */
@keyframes rotate {
    0% { transform: rotateX(0deg) rotateY(0deg); }
    100% { transform: rotateX(360deg) rotateY(360deg); }
}

/* Loading text styling */
.loading-text {
    margin-top: 20px;
    font-size: 2.5rem;
    letter-spacing: 0.1rem;
    color: #f5f5f5;
    animation: fadeIn 2s ease-in-out infinite;
    z-index: 1;
}

@keyframes fadeIn {
    0%, 100% { opacity: 1; }
    50% { opacity: 0.5; }
}
