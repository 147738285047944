:root {
    --main-color: #f59f1d;
    --sub-main-color: #A81611;

    --main-color-200: #fad78d;
    --main-color-300: #f8bd51;
    --main-color-400: #f59f1d;
    --main-color-500: #ef8211;
    --main-color-600: #d45f0b;
    --main-color-700: #b0410d;
    --main-color-800: #8f3211;
    --main-color-900: #752a12;

    --sub-main-color-200: #ffc8c6;
    --sub-main-color-300: #ffa19e;
    --sub-main-color-400: #ff6b66;
    --sub-main-color-500: #fd3d36;
    --sub-main-color-600: #eb1e17;
    --sub-main-color-700: #c6150f;
    --sub-main-color-800: #a81611;
    --sub-main-color-900: #871915;



    --green-color: #89B72B;
    --orange-color: #F8B01D;
    --black-color: #000000;
    --red-color: #ff6347;
    --white-color: #ffffff;
}

.btn-yellow-500 {
    background-color: var(--main-color-500);
}

.btn-yellow-default {
    background-color: var(--main-color-500);
    color: var(--white-color);
    margin: 0px 15px;
}

.btn-yellow-default:hover {
    background-color: var(--main-color-600);
    color: var(--white-color);
    margin: 0px 15px;
}

.btn-yellow-active {
    background-color: var(--main-color-700);
    color: var(--white-color);
    margin: 0px 15px;
}

.btn-yellow-disable {
    background-color: var(--main-color-200);
    color: var(--main-color-400);
    margin: 0px 15px;
}


.btn-red-default {
    background-color: var(--sub-main-color-500);
    color: var(--white-color);
    margin: 0px 15px;
}

.btn-red-default:hover {
    background-color: var(--sub-main-color-600);
    color: var(--white-color);
    margin: 0px 15px;
}

.btn-red-active {
    background-color: var(--sub-main-color-700);
    color: var(--white-color);
    margin: 0px 15px;
}

.btn-red-disable {
    background-color: var(--sub-main-color-200);
    color: var(--sub-main-color-400);
    margin: 0px 15px;
}

.title-content-common {
    font-size: 25px;
    padding-bottom: 16px;
    color: var(--sub-main-color-800);
}

.main-card-box {
    border-top: 3px solid var(--sub-main-color-400);
}

.swal2-backdrop-show {
    z-index: 9999 !important;
}

/* Countreport */
.dashboard-count-box {
    border-radius: 10px;
}

.dashboard-count-box .icon {
    width: 40px;
    height: 40px;
}

.dashboard-count-box h5 {
    font-size: 16px;
}

/* Countreport */